<template lang="pug">
.contract
  .contract__pdf
    AppCard
      .flex.justify-center
        AppRow
          .button(@click="prev" :class="{disabled: currentPage == 1}")
            i.uil.uil-angle-left
          .button(@click="next" :class="{disabled: currentPage == 6}")
            i.uil.uil-angle-right
      pdf(:src="contractPDF" :page="currentPage")
  .contract__sing
    AppCard
      .contract__title Firma
      .sing
        VueSignaturePad(ref="signaturePad" :options="strokeOptions")
        .sing__line
    AppRow
      AppButton.mt-3(label="Clear" class="danger" @click="clearSignature")  
      AppButton.mt-3(label="Guardar" @click="saveSignature")    
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
import { axios } from "@/utils/axios";
import { store } from "@/store";
import pdf from "vue3-pdf";
import AppButton from "@/components/AppButton";
import AppRow from "@/components/AppRow";
import AppCard from "@/components/AppCard";
export default {
  components: { pdf, AppButton, AppRow, AppCard },
  setup() {
    const contractPDF = ref("");
    const signValue = ref("");
    const currentPage = ref(1);
    const signaturePad = ref("");
    const strokeOptions = {
      penColor: "blue",
      onBegin: () => {
        window.dispatchEvent(new Event("resize"));
      },
    };
    onMounted(async () => {
      try {
        store.APP_LOADING = true;
        const { data } = await axios.post("lead/contract", {
          id: 1816,
        });
        contractPDF.value = `data:application/pdf;base64,${data.payload}`;
      } catch (error) {
        console.log(error);
      } finally {
        store.APP_LOADING = false;
      }
    });

    const prev = () => {
      if (currentPage.value < 2) return;
      currentPage.value--;
    };

    const next = () => {
      if (currentPage.value > 5) return;
      currentPage.value++;
    };

    const clearSignature = () => {
      signaturePad.value.clearSignature();
    };

    const saveSignature = async () => {
      const { isEmpty, data } = signaturePad.value.saveSignature();
      if (isEmpty) return;
      signValue.value = data.replaceAll("data:image/png;base64,", "");
      try {
        store.APP_LOADING = true;
        await axios.post("lead/contract/sign", {
          id: 1816,
          sign: signValue.value,
        });
        await axios.post("lead/invoice", {
          id: 1816,
        });
        await axios.post("lead/send", {
          id: 1816,
        });
      } catch (error) {
        console.log(error);
      } finally {
        store.APP_LOADING = false;
      }
    };

    return {
      contractPDF,
      currentPage,
      strokeOptions,
      signaturePad,
      clearSignature,
      saveSignature,
      prev,
      next,
      signValue,
    };
  },
};
</script>

<style lang="scss" scoped>
.contract {
  @screen md {
    @apply grid;
    @apply gap-4;
    @apply grid-flow-col;
    @apply auto-cols-fr;
  }
  &.margin {
    @apply mt-2;
    @apply mb-5;
  }
  &__title {
    @apply text-bluegray-400;
    @apply uppercase;
    @apply text-sm;
    @apply font-medium;
    @apply mb-2;
    @apply inline-block;
    @apply tracking-wide;
  }
  &__sing {
    @apply h-56;
  }
  &__pdf {
    .button {
      @apply text-center;
      @apply h-6;
      @apply w-6;
      @apply bg-gignetgreen-200;
      @apply rounded-full;
      @apply text-gignetblue-400;
    }
    .disabled {
      @apply bg-gray-100;
      @apply text-gray-400;
      @apply cursor-not-allowed;
    }
  }
}
.sing {
  @apply rounded-lg;
  @apply bg-gignetblue-100;
  @apply h-28;
  &__line {
    margin-top: -25px;
    @apply border-dashed;
    @apply mx-2;
    @apply border-t-2;
    @apply border-gignetblue-200;
    @apply h-4;
  }
}
</style>
